import React, { createRef, PureComponent } from "react";
import Swiper from "swiper/bundle";
import { Scene, PointLayer } from "@antv/l7";
import { GaodeMap } from "@antv/l7-maps";
import CountUp from "react-countup";
import { transWord } from "./constant/trans";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import "./App.scss";
import video from "./assets/video.mp4";
import launchData from "./assets/launch_sites.csv";
import video2 from "./assets/video2.mp4";

import page1Image1 from "./assets/images/page1Bg.png";
import page1Image2 from "./assets/images/page1Image2.jpg";
import page1Image3 from "./assets/images/page1Image3.jpg";
import page3Image1 from "./assets/images/page3Bg.png";
import page3Image2 from "./assets/images/page3Image1.png";
import page3Image3 from "./assets/images/page3Image2.png";
import page13Image1 from "./assets/images/13-1.png";
import page5Image1 from "./assets/images/page5Image1.png";
import page4Image1 from "./assets/images/page4Image1.png";
import page7Image1 from "./assets/images/page7Image1.png";
import page9Image1 from "./assets/images/page9Image1.png";
import page9Image2 from "./assets/images/page9Image2.png";
import page9Image3 from "./assets/images/page9Image3.png";
import page8Image1 from "./assets/images/page8Image1.png";
import page8Image2 from "./assets/images/page8Image2.png";
import page8Image3 from "./assets/images/page8Image3.png";
import page8Image4 from "./assets/images/page8Image4.png";
import page8Image5 from "./assets/images/page8Image5.png";
import code1 from "./assets/images/code1.png";
import code2 from "./assets/images/code2.png";
import cover from "./assets/images/cover.png";

const tabList = [
  {
    tabValue: 1,
    tabLable: "主机厂",
    tabImage: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 41],
  },
  {
    tabValue: 2,
    tabLable: "出行平台",
    tabImage: [17, 18, 19],
  },
  {
    tabValue: 3,
    tabLable: "开发商及物业公司",
    tabImage: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
  },
  {
    tabValue: 4,
    tabLable: "战略合作方",
    tabImage: [34, 35, 36, 37, 38, 39, 40],
  },
];

const tabList2 = [
  {
    tabValue: 1,
    tabLable: "专业资质",
    tabImage: [
      {
        value: 1,
        desc: "安全生产许可证",
      },
      {
        value: 2,
        desc: "建筑业企业资质证书",
      },
      {
        value: 3,
        desc: "ISO质量体系认证",
      },
      {
        value: 4,
        desc: "莱茵认证",
      },
    ],
  },
  {
    tabValue: 2,
    tabLable: "行业资质",
    tabImage: [
      {
        value: 5,
        desc: "上海充电桩联盟会员单位",
      },
      {
        value: 6,
        desc: "上海物业协会会员单位",
      },
      {
        value: 7,
        desc: "北京物业协会会员单位",
      },
      {
        value: 8,
        desc: "深圳停车场协会会员单位",
      },
    ],
  },
  {
    tabValue: 3,
    tabLable: "软著和专利",
    tabImage: [
      {
        value: 9,
        desc: "2017SR043458",
      },
      {
        value: 10,
        desc: "2017SR369546",
      },
      {
        value: 11,
        desc: "2017SR603730",
      },
      {
        value: 12,
        desc: "2017SR615234",
      },
      {
        value: 13,
        desc: "2017SR621908",
      },
      {
        value: 14,
        desc: "2017SR714468",
      },
      {
        value: 15,
        desc: "ZL201730156648.2",
      },
      {
        value: 16,
        desc: "ZL201820037281.1",
      },
      {
        value: 17,
        desc: "ZL201820038053.6",
      },
      {
        value: 18,
        desc: "检验报告",
      },
    ],
  },
];

export const isPC = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};

class App extends PureComponent {
  state = {
    lang: "cn",
    activeImage: 1,
    activeImage2: 1,
    navBarClass: "",
    activePage: 0,
    pageClass: {
      page1: 0,
      page2: 0,
      page3: 0,
      page4: 0,
      page5: 0,
      page6: 0,
      page7: 0,
      page8: 0,
      page9: 0,
      page10: 0,
      page11: 0,
      page12: 0,
    },
  };

  componentDidMount() {
    const { pageClass } = this.state;

    this.swiper1 = new Swiper(".swiper-container1", {
      direction: "vertical",
      slidesPerView: isPC() ? 1 : 2,
      centeredSlides: isPC() ? false : true,
      centeredSlidesBounds: isPC() ? false : true,
      spaceBetween: 0,
      height: window.innerHeight,
      allowTouchMove: isPC() ? false : true,
      updateOnWindowResize: true,
      mousewheel: { thresholdTime: 600, thresholdDelta: 30 },
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 2,
        loadOnTransitionStart: true,
      },
      on: {
        slideChangeTransitionStart: this.handleSlideChange,
      },
    });
    this.swiper2 = new Swiper(".swiper-container2", {
      navigation: {
        nextEl: ".swiper-button-next2",
        prevEl: ".swiper-button-prev2",
      },
      updateOnWindowResize: true,
      autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      pagination: { el: ".swiper-pagination", clickable: true },
    });
    this.swiper3 = new Swiper(".swiper-container3", {
      autoplay: true,
      updateOnWindowResize: true,
      navigation: {
        nextEl: ".swiper-button-next3",
        prevEl: ".swiper-button-prev3",
      },
    });
    this.swiper4 = new Swiper(".swiper-container4", {
      autoplay: true,
      updateOnWindowResize: true,
      navigation: {
        nextEl: ".swiper-button-next4",
        prevEl: ".swiper-button-prev4",
      },
    });

    const scene = new Scene({
      id: "map",
      map: new GaodeMap({
        pitch: 0,
        style: "dark",
        center: [104.288144, 31.239692],
        zoom: 4,
        // dragEnable: false, //是否允许地图拖拽
        keyboardEnable: false, // 是否允许形键盘事件
        doubleClickZoom: true, // 双击放大
        zoomEnable: false, // 滚动缩放
        rotateEnable: false, // 旋转
      }),
    });
    scene.on("loaded", () => {
      fetch(launchData)
        .then((res) => res.text())
        .then((data) => {
          const pointLayer = new PointLayer({})
            .source(data, {
              parser: {
                type: "csv",
                x: "Longitude",
                y: "Latitude",
              },
            })
            .shape("circle")
            .active(true)
            .animate(true)
            .size(20)
            .color("#f3ffc0")
            .style({
              opacity: 1,
            });
          scene.addLayer(pointLayer);
        });
    });

    this.changeNavBar(true);
    this.setState({
      pageClass: { ...pageClass, page1: 1 },
    });

    this.video.current.play();
  }

  handleSlideChange = (v) => {
    const { pageClass } = this.state;
    const pageName = `page${v.activeIndex + 1}`;
    this.setState({
      pageClass: { ...pageClass, [pageName]: 1 },
      activePage: v.activeIndex,
    });
    if (v.activeIndex === 0) {
      this.changeNavBar(true);
    } else {
      this.changeNavBar();
    }
  };

  changeNavBar = (type) => {
    clearTimeout(this.timer);
    this.setState({
      navBarClass: "show",
    });
    if (!type) {
      this.timer = setTimeout(() => {
        this.setState({
          navBarClass: "",
        });
      }, 3000);
    }
  };

  hideNavBar = () => {
    const { activePage } = this.state;
    if (activePage !== 0) {
      this.setState({
        navBarClass: "",
      });
    }
  };

  changeTrans = () => {
    const { lang } = this.state;
    if (lang === "cn") {
      this.setState({
        lang: "en",
      });
    } else {
      this.setState({
        lang: "cn",
      });
    }
  };

  changeTab = (e, v, type) => {
    e.preventDefault();
    if (type === 2) {
      this.setState({
        activeImage2: v,
      });
    } else {
      this.setState({
        activeImage: v,
      });
    }
  };

  changeSlide = (v) => {
    this.swiper1.slideTo(v);
  };

  video = createRef();

  navApp = () => {
    window.location.href = "/mobile/mobile.html";
  };

  render() {
    const { lang, activeImage, activeImage2, pageClass, navBarClass } =
      this.state;

    const tabListElem = tabList.map((val) => (
      <div
        className={`tab-item ${activeImage === val.tabValue ? "active" : ""}`}
        key={val.tabValue}
        onClick={(e) => this.changeTab(e, val.tabValue)}
      >
        {val.tabLable}
      </div>
    ));
    const activeImageElem = tabList
      .filter((val) => val.tabValue === activeImage)[0]
      .tabImage.map((val) => <div key={val} className={`image image${val}`} />);

    const tabListElem2 = tabList2.map((val) => (
      <div
        className={`tab-item ${activeImage2 === val.tabValue ? "active" : ""}`}
        key={val.tabValue}
        onClick={(e) => this.changeTab(e, val.tabValue, 2)}
      >
        {val.tabLable}
      </div>
    ));
    const activeImageElem2 = tabList2
      .filter((val) => val.tabValue === activeImage2)[0]
      .tabImage.map((val) => (
        <div className="image-wrap" key={val.value}>
          <div className={`image image${val.value}`} />
          <div className="desc">{val.desc}</div>
        </div>
      ));

    return (
      <div className="App">
        <div
          className={`nav-bar ${navBarClass}`}
          onMouseEnter={this.changeNavBar}
          onMouseLeave={this.hideNavBar}
        >
          <div className="wrapper">
            <div className="content">
              <div onClick={() => this.changeSlide(0)} className="logo image" />
              <ul>
                <li onClick={() => this.changeSlide(1)}>
                  {transWord.navBar.li1[lang]}
                </li>
                <li onClick={() => this.changeSlide(5)}>
                  {transWord.navBar.li2[lang]}
                </li>
                <li onClick={() => this.changeSlide(8)}>
                  {transWord.navBar.li3[lang]}
                </li>
                <li onClick={() => this.changeSlide(10)}>
                  {transWord.navBar.li4[lang]}
                </li>
                <li onClick={this.changeTrans}>{transWord.navBar.li5[lang]}</li>
                <li>{transWord.navBar.li6[lang]}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="swiper-container swiper-container1">
          <div className="swiper-wrapper">
            {/* 首页 1*/}
            <div className="swiper-slide">
              <div className="swiper-container swiper-container2">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div
                      data-background={page1Image1}
                      className={`page page1 swiper-lazy image ${
                        pageClass.page1 ? "active" : ""
                      }`}
                    >
                      <div className="swiper-lazy-preloader"></div>
                      <video
                        muted={true}
                        controls={false}
                        ref={this.video}
                        loop
                        poster={cover}
                        width="100%"
                        height="100%"
                      >
                        <source src={video2} type="video/mp4" />
                        <p>
                          Your browser doesn't support HTML5 video. Here is a{" "}
                          <a href={video}>link to the video</a> instead.
                        </p>
                      </video>
                      <div className="content">
                        <div className="title1">{transWord.page1.w1[lang]}</div>
                        <div className="title2">{transWord.page1.w2[lang]}</div>
                        <div className="num-group">
                          <div className="num-item">
                            <div className="num">
                              {pageClass.page1 && (
                                <CountUp
                                  separator=","
                                  start={0}
                                  end={7374000}
                                  duration="3"
                                />
                              )}
                            </div>
                            <div className="title">
                              {transWord.page1.w3[lang]}
                            </div>
                          </div>
                          <div className="num-item">
                            <div className="num">
                              {pageClass.page1 && (
                                <CountUp
                                  separator=","
                                  start={0}
                                  end={74163}
                                  duration="3"
                                />
                              )}
                            </div>
                            <div className="title">
                              {transWord.page1.w4[lang]}
                            </div>
                          </div>
                          <div className="num-item">
                            <div className="num">
                              {pageClass.page1 && (
                                <CountUp
                                  separator=","
                                  start={0}
                                  end={168}
                                  duration="3"
                                />
                              )}
                            </div>
                            <div className="title">
                              {transWord.page1.w5[lang]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="swiper-lazy-preloader"></div>
                    <div
                      data-background={page1Image2}
                      className="page page1 swiper-lazy image"
                    ></div>
                  </div>
                  <div className="swiper-slide">
                    <div className="swiper-lazy-preloader"></div>
                    <div
                      data-background={page1Image3}
                      className="page page1 swiper-lazy image"
                    ></div>
                  </div>
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-prev swiper-button-prev2"></div>
                <div className="swiper-button-next swiper-button-next2"></div>
              </div>
            </div>
            {/* 关于我们 2*/}
            <div className="swiper-slide">
              <div className={`page page10 ${pageClass.page2 ? "active" : ""}`}>
                <div className="wrapper">
                  <div className="page-title white">
                    <p>关于我们</p>
                    <p>About Us</p>
                  </div>
                  <div className="content">
                    <div className="up">
                      <div className="video">
                        <video controls width="500px">
                          <source src={video} type="video/mp4" />
                          <p>
                            Your browser doesn't support HTML5 video. Here is a{" "}
                            <a href={video}>link to the video</a> instead.
                          </p>
                        </video>
                      </div>
                      <div className="desc">
                        <div className="desc-title">
                          上海森通智达新能源技术服务有限公司
                        </div>
                        <div className="desc-detail">
                          上海森通智达新能源技术服务有限公司成立于2014年初，总部位于上海，是国内专业的新能源汽车充电产品和服务解决方案提供商，在充电站的网络开拓、安装建设、流量运营和设备运维等领域有丰富的经验，同时分别针对交流桩和直流桩的应用场景开发了完整的充电产品解决方案，服务客户包括滴滴出行、欧了出行、特斯拉、宝马、奔驰、奥迪、保时捷、蔚来、小鹏、威马、拜腾、理想、上汽、上汽通用、比亚迪等国内主流出行平台和新能源汽车企业以及碧桂园、万科、彩生活、金地、中海等国内知名开发商和物业公司，服务范围覆盖全国超过100个城市，累计服务新能源汽车用户超过50000个。
                        </div>
                      </div>
                    </div>
                    <div className="down">
                      <div className="time-line-list">
                        <div className="time-line-item big">
                          <div className="item-title">CEO寄语</div>
                          <div className="item-desc">
                            <p>
                              森通智达一直秉承高效、专业和客户至上的经营理念，通过不断地技术创新和运营经验积累，公司致力于提供更有效和更具经济性的新能
                              源汽车充电解决方案，为普及新能源汽车和分布式能源系统尽绵薄之力。
                            </p>
                          </div>
                        </div>
                        <div className="time-line-item">
                          <div className="item-title">2016年之前</div>
                          <div className="item-desc">
                            <p>上汽充电服务商</p>
                            <p>比亚迪充电服务商</p>
                            <p>中德E车充电项目指定充电服务商</p>
                            <p>奇瑞充电服务商</p>
                            <p>奔驰充电服务商</p>
                          </div>
                        </div>
                        <div className="time-line-item">
                          <div className="item-title">2017年</div>
                          <div className="item-desc">
                            <p>宝马充电服务商</p>
                            <p>特斯拉充电服务商</p>
                            <p>蔚来充电服务商</p>
                            <p>奥迪充电服务商</p>
                          </div>
                        </div>
                        <div className="time-line-item">
                          <div className="item-title">2018年</div>
                          <div className="item-desc">
                            <p>世界500强能源集团ENGIE战略投资</p>
                            <p>小鹏充电服务商</p>
                            <p>威马充电服务商</p>
                            <p>上汽通用充电服务商</p>
                          </div>
                        </div>
                        <div className="time-line-item">
                          <div className="item-title">2019年</div>
                          <div className="item-desc">
                            <p>滴滴出行充电合作方</p>
                            <p>欧了出行充电合作方</p>
                            <p>安联保险充电合作方</p>
                            <p>保时捷充电服务商</p>
                            <p>理想充电服务商</p>
                          </div>
                        </div>
                        <div className="time-line-item">
                          <div className="item-title">2020年</div>
                          <div className="item-desc">
                            <p>第一座储能快充站落地宁波</p>
                            <p>住友商事战略合作方</p>
                            <p>青山集团战略合作方</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 地图 3*/}
            <div className="swiper-slide">
              <div className={`page page2 ${pageClass.page3 ? "active" : ""}`}>
                <div id="map"></div>
                <div className="content">
                  <div className="page-title white">
                    <p>业务规模</p>
                    <p>Business Scale</p>
                  </div>
                  <div className="desc">
                    <div className="desc-title">运营效率</div>
                    <p>
                      行业平均水平<span>两倍</span>以上
                    </p>
                    <p>
                      交流充电桩平均利用率<span>9%</span>，直流充电桩平均利用率
                      <span>30%</span>
                    </p>
                    <p>交流桩站点单桩月度充电量最高可达600度</p>
                    <p>直流桩站点单桩月度充电量最高可达20000度</p>
                  </div>
                  <div className="desc2">
                    <div className="desc-item">
                      <div className="num">
                        {pageClass.page3 && (
                          <CountUp start={0} end={150} duration="3" />
                        )}
                        +
                      </div>
                      <div className="word">服务城市</div>
                    </div>
                    <div className="desc-item">
                      <div className="num">
                        {pageClass.page3 && (
                          <CountUp start={0} end={13000} duration="3" />
                        )}
                        +
                      </div>
                      <div className="word">服务社区数</div>
                    </div>
                    <div className="desc-item">
                      <div className="num">
                        {pageClass.page3 && (
                          <CountUp start={0} end={60000} duration="3" />
                        )}
                        +
                      </div>
                      <div className="word">充电桩安装数</div>
                    </div>
                    <div className="desc-item">
                      <div className="num">
                        {pageClass.page3 && (
                          <CountUp start={0} end={5000} duration="3" />
                        )}
                        +
                      </div>
                      <div className="word">自营充电桩数</div>
                    </div>
                    <div className="desc-item">
                      <div className="num">
                        {pageClass.page3 && (
                          <CountUp start={0} end={50000} duration="3" />
                        )}
                        +
                      </div>
                      <div className="word">加盟充电桩数</div>
                    </div>
                    <div className="desc-item">
                      <div className="num">
                        {pageClass.page3 && (
                          <CountUp start={0} end={20000} duration="3" />
                        )}
                        +
                      </div>
                      <div className="word">平台用户数</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 战略投资伙伴 4*/}
            <div className="swiper-slide">
              <div className={`page page3 ${pageClass.page4 ? "active" : ""}`}>
                <div
                  data-background={page3Image1}
                  className="swiper-lazy up image"
                >
                  <div className="content">
                    <div className="left-title">
                      <div className="title1">
                        ENGIE拥有百年悠久历史的工业遗产
                      </div>
                      <div className="title2">
                        源自历史悠久的诸多工业企业，近两百年以来始终致力于满足社会不断发展变化的需求。
                      </div>
                    </div>
                    <div className="right-card">
                      <p>战略投资伙伴</p>
                      <p>Strategic investment partner</p>
                      <p>法国能源集团ENGIE</p>
                    </div>
                  </div>
                </div>
                <div className="down">
                  <div className="content">
                    <div className="left-word">
                      <div className="title">ENGIE中国在华愿景及战略</div>
                      <div className="desc">
                        ENGIE致力于迎接我们在迈向更加低碳、更多分布式和更多数字化技术的能源革命进程中所面临的重大挑战。ENGIE的愿景是推动全球零碳转型，且为其未来发展聚焦于三个关键业务领域:天然气和可再生能源发电，能源基础设施网络，以及满足各类客户(个人客户、企业客户、城市及地区等)需求的综合能源解决方案。
                      </div>
                    </div>
                    <div className="right-card-list">
                      <div className="card-item">
                        <div
                          data-background={page3Image2}
                          className="swiper-lazy left-image image"
                        />
                        <div className="right">
                          <div className="title">
                            ENGIE集团董事长Clamadieu先生在上海举办的“绿色出行日”活动上表示：
                          </div>
                          <div className="desc">
                            “提供绿色出行服务是法能集团为城市提供零碳解决方案的核心组成部分。我们的目标是促进清洁能源的应用并提升出行能源的使用效率。”
                          </div>
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="right">
                          <div className="title">
                            ENGIE中国董事长兼首席执行官夏澜（Charlotte
                            ROULE）在媒体采访中说道：
                          </div>
                          <div className="desc">
                            “中国的能源转型可以成为一个范例。对我来说，就应该在此刻来到中国。我们所在的这个国家，很清醒地意识到它所面对的问题，这也是我们身处星球所面对的问题。中国做出的承诺很了不起，这不是一条容易走的道路。我深深意识到：能源转型正在中国发生，并从中国走向世界。”
                          </div>
                        </div>
                        <div
                          data-background={page3Image3}
                          className="swiper-lazy left-image image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ENGIE——全球领军企业 5*/}
            <div className="swiper-slide">
              <div className={`page page13 ${pageClass.page5 ? "active" : ""}`}>
                <div className="content">
                  <div className="up">
                    <div className="desc-list">
                      <div className="desc-item">
                        <div className="title">ENGIE——全球领军企业</div>
                        <div className="word">
                          <p>
                            ENGIE致力于迎接我们在迈向更加低碳、更多分布式和更多数字化技术的能源革命进程中所面临的重大挑战。
                          </p>
                          <p>
                            ENGIE的愿景是推动全球零碳转型，且为其未来发展聚焦于三个关键业务领域:
                          </p>
                          <p>
                            天然气和可再生能源发电，能源基础设施网络，以及满足各类客户(个人客户、企业客户、城市及地区等)需求的综合能源解决方案。
                          </p>
                        </div>
                      </div>
                      <div className="desc-item">
                        <div className="title">ENGIE进军中国新能源汽车市场</div>
                        <div className="word">
                          <p>
                            2018年9月，ENGIE与上海森通智达新能源技术服务有限公司(EVChong)签署了小额股权投资协议。上海森通智达新能源技术服务有限公司于2014年成立，是一家致力于新能源汽车相关服务的私营企业，业务涉及网络开拓、安装建设、流量运营、设备运维和产品开发等。
                          </p>
                        </div>
                      </div>
                      <div className="desc-item">
                        <div className="title">ENGIE在中国推动能源零碳转型</div>
                        <div className="word">
                          <p>
                            ENGIE在中国已有30多年的发展历史。目前，ENGIE在中国的业务涉足北京、上海、重庆和四川，并在太阳能发电、绿色交通、区域供冷&供热、能源效益服务、天然气热电联供和工程设计服务等领域设立有多家合资公司。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="down">
                    <div
                      data-background={page13Image1}
                      className="swiper-lazy image image1"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* 产品概览 6*/}
            <div className="swiper-slide">
              <div className={`page page5 ${pageClass.page6 ? "active" : ""}`}>
                <div className="wrapper">
                  <div className="page-title">
                    <p>产品概览</p>
                    <p>Product Overview</p>
                  </div>
                </div>
                <div className="content">
                  <div
                    data-background={page5Image1}
                    className="swiper-lazy image image2"
                  />
                  <div className="word space">
                    <div className="word-item">
                      <div className="title">交流充电产品</div>
                      <ul>
                        <li>单相交流充电桩</li>
                        <li>三相交流充电桩</li>
                        <li>集群充电系统</li>
                        <li>益虫能源路由器</li>
                      </ul>
                    </div>
                    <div className="word-item">
                      <div className="title">直流充电产品</div>
                      <ul>
                        <li>30kW-200kW单枪直流充电桩</li>
                        <li>60kW-200kW双枪直流充电桩</li>
                        <li>30kW-180kW智能分配系统</li>
                      </ul>
                    </div>
                    <div className="word-item">
                      <div className="title">快充储系统</div>
                      <ul>
                        <li>225kW/165kWh快充储能系统</li>
                        <li>240kW/250kWh快充储能系统</li>
                        <li>450kW/330kWh快充储能系统</li>
                      </ul>
                    </div>
                    <div className="word-item">
                      <div className="title">云端服务产品</div>
                      <ul>
                        <li>充电设施通信平台</li>
                        <li>充电设施运营支持平台</li>
                        <li>淘淘充小程序（To C）</li>
                        <li onClick={this.navApp} style={{ cursor: "pointer" }}>
                          淘淘充移动端（To C）
                        </li>
                        <li>益虫充电移动端（To C）</li>
                        <li>充电运营SAAS平台（To B）</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 智能充电解决方案 7*/}
            <div className="swiper-slide">
              <div className={`page page7 ${pageClass.page7 ? "active" : ""}`}>
                <div className="wrapper2">
                  <div className="page-title">
                    <p>智能充电解决方案</p>
                    <p>Smart Charging Solution</p>
                  </div>
                </div>
                <div className="content">
                  <div
                    data-background={page7Image1}
                    className="swiper-lazy desc-list image"
                  >
                    {/* <div className="wrapper">
                      <div className="desc desc1">
                        <div className="s-title">合作伙伴管理平台</div>
                        <ul className="list">
                          <li>站点信息管理</li>
                          <li>设备及用户行为监控</li>
                          <li>计价模型设置</li>
                          <li>用户账户管理</li>
                          <li>对账管理</li>
                          <li>分账管理</li>
                          <li>支付实时到账</li>
                        </ul>
                      </div>
                      <div className="desc desc2">
                        <div className="s-title">移动客户端</div>
                        <ul className="list">
                          <li>远程实时监控充电状态</li>
                          <li>充电桩及车位预约</li>
                          <li>社区充电轻社交</li>
                          <li>充值营销活动</li>
                          <li>故障报修</li>
                        </ul>
                      </div>
                      <div className="desc desc3">
                        <div className="s-title">智能车位管理技术</div>
                        <ul className="list">
                          <li>解决油车占位问题</li>
                          <li>提高充电桩利用率</li>
                          <li>优化车位资源</li>
                          <li>车位状态识别</li>
                        </ul>
                      </div>
                      <div className="desc desc4">
                        <div className="s-title">有序充电</div>
                        <ul className="list">
                          <li>收集和分析用电需求和站点使用情况</li>
                          <li>动态调整和分配充电功率</li>
                          <li>总电容量不变的情况下，可实现充电车位扩容100</li>
                          <li>动态响应电网和智能楼宇系统的限电需求</li>
                          <li>支持容灾保护</li>
                        </ul>
                      </div>
                      <div className="desc desc5">
                        <div className="s-title">充电运营管理平台</div>
                        <ul className="list">
                          <li>站点及计价体系建立</li>
                          <li>设备及车位监控</li>
                          <li>用户账号管理及营销</li>
                          <li>合作伙伴管理及清算</li>
                          <li>运营KPI报表</li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* 产品介绍 8*/}
            <div className="swiper-slide">
              <div className={`page page9 ${pageClass.page8 ? "active" : ""}`}>
                <div className="wrapper">
                  <div className="page-title">
                    <p>产品介绍</p>
                    <p>Product Overview</p>
                  </div>
                </div>
                <div className="content">
                  <div className="swiper-container swiper-container3">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="product-page">
                          <div className="table-list">
                            <div className="table-item head">
                              <h3 className="green">交流充电产品</h3>
                              <p className="green">益虫Ⅳ交流充电桩技术参数</p>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>额定功率：3.5kW~22kW</p>
                              </div>
                              <div className="desc">
                                <p>外形尺寸：360mm*275mm*125mm</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>工作频率：50±10%（HZ）</p>
                              </div>
                              <div className="desc">
                                <p>工作安装方式：挂壁，支架</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>电源类型：单相电/三相电</p>
                              </div>
                              <div className="desc">
                                <p>环境湿度：≤95% 无冷凝</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>待机功耗：＜10W</p>
                              </div>
                              <div className="desc">
                                <p>工作电压：220VAC-380VAC</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>通讯接口：4G / CAN / 以太网 / RS485</p>
                              </div>
                              <div className="desc">
                                <p>工作温度：-20℃~50℃</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>
                                  电气保护：短路、过欠压、过载、漏电、接地、急停，浪涌
                                </p>
                              </div>
                              <div className="desc">
                                <p>防护等级：IP55，防潮、防霉、防盐雾</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>线缆长度：3m/5m/7m（可定制）</p>
                              </div>
                              <div className="desc">
                                <p>桩体质量：6kg（含线缆）</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>授权方式：RFID刷卡 / 微信扫码</p>
                              </div>
                              <div className="desc">
                                <p>
                                  执行标准：GBT 18487.1-2015 / GBT 20234.1-2015
                                  / GBT 20234.2-2015 NBT 33002-2010 / NBT
                                  33008.2-2013
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            data-background={page9Image1}
                            className="swiper-lazy image page9Image1"
                          />
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="product-page">
                          <div
                            data-background={page9Image2}
                            className="swiper-lazy image page9Image2"
                          />
                          <div className="table-list">
                            <div className="table-item head">
                              <h3 className="green">集群充电系统</h3>
                              <p className="green">一拖N技术参数</p>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>额定功率：14kW</p>
                              </div>
                              <div className="desc">
                                <p>输入/输出电压：AC220V±20%</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>工作频率：50Hz±10%</p>
                              </div>
                              <div className="desc">
                                <p>使用环境：-20℃~50℃</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>环境湿度：≤95%　无冷凝</p>
                              </div>
                              <div className="desc">
                                <p>使用海拔高度：≤2000m</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>输出电流：16A/32A</p>
                              </div>
                              <div className="desc">
                                <p>防护等级：IP54</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>通讯接口：4G / RS485</p>
                              </div>
                              <div className="desc">
                                <p>授权方式：微信扫码</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>线缆长度：3m / 5m / 7m（可定制）</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>
                                  外形尺寸：450mm*130mm*550mm（主机）　285mm*163mm*390mm（从机）
                                </p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>执行标准：</p>
                                <p>
                                  GBT 18487.1-2015 / NBT 33008.2-2013 / NBT
                                  33002-2010 / GBT 20234.1-2015 / GBT
                                  20234.2-2015
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="product-page">
                          <div className="table-list">
                            <div className="table-item head">
                              <h3 className="green">益虫能源路由器</h3>
                              <p className="green">智能电表技术参数</p>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>工作电压： 220VAC</p>
                              </div>
                              <div className="desc">
                                <p>工作温度：-25℃～60℃</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>工作频率：50±10%（HZ）</p>
                              </div>
                              <div className="desc">
                                <p>工作模式：扫码预付费启停 </p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>精度：1.0</p>
                              </div>
                              <div className="desc">
                                <p>安装方式：壁挂</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>标定电流：5（20）A、10（40）A、15（60）A</p>
                              </div>
                              <div className="desc">
                                <p>标准：DL/T645-2007　GB/T17215.321-2008</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>待机功耗：≤1w</p>
                              </div>
                              <div className="desc">
                                <p>脉冲常数：800-3200imp/kWh</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>重量：0.8kg</p>
                              </div>
                              <div className="desc">
                                <p>通信方式：4G、RS485</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>尺寸：112×160×58mm</p>
                              </div>
                              <div className="desc">
                                <p>显示方式：液晶显示</p>
                              </div>
                            </div>
                            <div className="table-item">
                              <div className="desc">
                                <p>
                                  主要功能：⒈计量功能　 ⒉远程费控功能　
                                  ⒊测量及监测　 ⒋事件记录　 ⒌显示功能
                                  ⒍报警功能　 ⒎冻结功能　　　 ⒏计时功能　　
                                  ⒐脉冲输出　 ⒑负荷曲线
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            data-background={page9Image3}
                            className="swiper-lazy image page9Image3"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="swiper-button-prev swiper-button-prev3"></div>
                    <div className="swiper-button-next swiper-button-next3"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* 服务概览 9*/}
            <div className="swiper-slide">
              <div className={`page page4 ${pageClass.page9 ? "active" : ""}`}>
                <div className="wrapper">
                  <div className="page-title">
                    <p>服务概览</p>
                    <p>Service Overview</p>
                  </div>
                </div>
                <div className="content">
                  <div className="word">
                    <div className="word-item">
                      <div className="title">
                        <span>1</span>网点拓展
                      </div>
                      <ul>
                        <li>城市准入分析</li>
                        <li>城市充电站整体规划</li>
                        <li>城市充电站选址标准化</li>
                        <li>城市停车场资源洽谈及签约</li>
                      </ul>
                    </div>
                    <div className="word-item">
                      <div className="title">
                        <span>2</span>安装建设
                      </div>
                      <ul>
                        <li>全国性安装建设能力</li>
                        <li>标准化安装建设流程</li>
                        <li>标准化充电场站设计</li>
                      </ul>
                    </div>
                    <div className="word-item">
                      <div className="title">
                        <span>3</span>流量运营
                      </div>
                      <ul>
                        <li>精准选址确保基础流量</li>
                        <li>优质服务保证流量粘度</li>
                        <li>线上线下流量整合带来增量流量</li>
                      </ul>
                    </div>
                    <div className="word-item">
                      <div className="title">
                        <span>4</span>设备运维
                      </div>
                      <ul>
                        <li>云平台实时监控充电站状态</li>
                        <li>五年以上经验的充电桩运维团队</li>
                        <li>四万个充电桩运维经验</li>
                      </ul>
                    </div>
                    <div className="word-item">
                      <div className="title">
                        <span>5</span>投资持有
                      </div>
                      <ul>
                        <li>合作模式灵活</li>
                        <li>多种融资渠道</li>
                        <li>城市充电站选址标准化</li>
                        <li>城市停车场资源洽谈及签约</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    data-background={page4Image1}
                    className="swiper-lazy image image1"
                  />
                </div>
              </div>
            </div>
            {/* 案例介绍 10*/}
            <div className="swiper-slide">
              <div className={`page page8 ${pageClass.page10 ? "active" : ""}`}>
                <div className="wrapper">
                  <div className="page-title">
                    <p>案例介绍</p>
                    <p>Business Case</p>
                  </div>
                </div>
                <div className="content">
                  <div className="swiper-container swiper-container4">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="case-wrap">
                          <div className="left">
                            <div
                              data-background={page8Image1}
                              className="swiper-lazy image1 image"
                            >
                              <div className="desc">
                                <div className="desc-title">小桔充电</div>
                                小桔充电为滴滴出行旗下品牌，滴滴拥有国内最大的电动车流量平台，小桔充电是全方位、一站式的充电服务平台，为新能源⻋车主提供优质、实惠、快捷、值得信赖的充电服务，滴滴平台内超级用户流量，与
                                合作伙伴充分共享滴滴平台内用户资源，最大化发挥平台运作效率。
                              </div>
                            </div>
                          </div>
                          <div className="right">
                            <div
                              data-background={page8Image2}
                              className="swiper-lazy image2 image"
                            >
                              <div className="desc">
                                <div className="desc-title">小鹏超充站建设</div>
                                承接全国范围内小鹏超充站建设项目，提供一站式建设服务，包括现场勘查、强弱电施工方案评估及设计、场地方协调、强弱电施工管理、充电桩上电测试及交付等，2019年全国范围内累计落地13个超充站项目。
                              </div>
                            </div>
                            <div
                              data-background={page8Image3}
                              className="swiper-lazy image3 image"
                            >
                              <div className="desc">
                                <div className="desc-title">宁波储充站</div>
                                快充结合储能的充电模式，不仅节省了电力扩容的成本和周期，通过核心能源管理，大大增加了削峰填谷的经济性，并且可以灵活调整充电功率，实现超快充功能。
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="case-wrap">
                          <div className="left">
                            <div
                              data-background={page8Image4}
                              className="swiper-lazy image4 image"
                            >
                              <div className="desc">
                                <div className="desc-title">
                                  上海浦东机场停机坪充电桩运维项目
                                </div>
                                配合上海机场“蓝天保卫战”计划，为浦东机场T1、T2航站楼、卫星厅北港湾及卫星厅南港湾共计52套充电桩提供7*24小时运维工作，共配置22名专业运维人员，为浦东机场停机坪充电桩的可靠安全运营提供全方位的保障。
                              </div>
                            </div>
                          </div>
                          <div className="right">
                            <div
                              data-background={page8Image5}
                              className="swiper-lazy image5 image"
                            >
                              <div className="desc">
                                <div className="desc-title">
                                  上海真如副中心商业综合体项目
                                </div>
                                为上海最大商业综合体项目真如副中心提供充电桩硬件、施工、监控平台搭建、用户流量运营、停车
                                场管理系统对接等一站式完整解决方案，真如副中心有超过4000个车位，配置充电桩近300个。
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-button-prev swiper-button-prev4"></div>
                    <div className="swiper-button-next swiper-button-next4"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* 合作伙伴 11*/}
            <div className="swiper-slide">
              <div
                className={`page page11 ${pageClass.page11 ? "active" : ""}`}
              >
                <div className="wrapper">
                  <div className="page-title">
                    <p>合作伙伴</p>
                    <p>Business Partner</p>
                  </div>
                  <div className="content">
                    <div className="tab-list">{tabListElem}</div>
                    <div className="active-image">{activeImageElem}</div>
                  </div>
                </div>
              </div>
            </div>
            {/* 企业资质 12*/}
            <div className="swiper-slide">
              <div
                className={`page page12 ${pageClass.page12 ? "active" : ""}`}
              >
                <div className="wrapper">
                  <div className="page-title">
                    <p>企业资质</p>
                    <p>Enterprise Qualifications</p>
                  </div>
                  <div className="content">
                    <div className="tab-list">{tabListElem2}</div>
                    <div className="active-image">{activeImageElem2}</div>
                  </div>
                </div>
                <div className="bottom">
                  <div className="content">
                    <div className="up">
                      <div className="left">
                        <div className="item">
                          <div
                            onClick={() => this.changeSlide(0)}
                            className="logo image"
                          />
                          <div className="desc">森通智达 低碳出行服务商</div>
                        </div>
                        <div className="item">
                          <p onClick={() => this.changeSlide(1)}>
                            {transWord.navBar.li1[lang]}
                          </p>
                          <p onClick={() => this.changeSlide(3)}>
                            {transWord.navBar.p1[lang]}
                          </p>
                        </div>
                        <div className="item">
                          <p onClick={() => this.changeSlide(5)}>
                            {transWord.navBar.li2[lang]}
                          </p>
                          <p onClick={() => this.changeSlide(5)}>
                            {transWord.navBar.p2[lang]}
                          </p>
                          <p onClick={() => this.changeSlide(6)}>
                            {transWord.navBar.p3[lang]}
                          </p>
                          <p onClick={() => this.changeSlide(7)}>
                            {transWord.navBar.p4[lang]}
                          </p>
                        </div>
                        <div className="item">
                          <p onClick={() => this.changeSlide(8)}>
                            {transWord.navBar.li3[lang]}
                          </p>
                          <p onClick={() => this.changeSlide(8)}>
                            {transWord.navBar.p5[lang]}
                          </p>
                          <p onClick={() => this.changeSlide(9)}>
                            {transWord.navBar.p6[lang]}
                          </p>
                        </div>
                        <div className="item">
                          <p onClick={() => this.changeSlide(10)}>
                            {transWord.navBar.li4[lang]}
                          </p>
                          <p onClick={() => this.changeSlide(11)}>
                            {transWord.navBar.p7[lang]}
                          </p>
                        </div>
                      </div>
                      <div className="center">
                        <p>
                          官方网站：
                          <span className="green">www.evchong.com</span>
                        </p>
                        <p>
                          服务邮箱：
                          <span className="green">service@evchong.com</span>
                        </p>
                        <p>
                          服务热线：<span className="green">400-820-6925</span>
                        </p>
                      </div>
                      <div className="right">
                        <div
                          data-background={code1}
                          className="swiper-lazy code1 image"
                        />
                        <div
                          data-background={code2}
                          className="swiper-lazy code2 image"
                        />
                      </div>

                      {/*<div className="downImg">*/}
                      {/*/!*<a target="_blank" href="https://xyt.xcc.cn/getpcInfo?sn=1559087899188936704&language=CN&certType=8&url=evchong.com">服务器证书</a>*!/*/}
                      {/*<a target="_blank" href="https://xyt.xcc.cn/getpcInfo?sn=1559087899188936704&certType=8&url=evchong.com&language=CN">*/}
                      {/*<img className="img" src="https://program.xinchacha.com/xyt/xcc_small_ssl.png"/>*/}
                      {/*</a>*/}
                      {/*</div>*/}

                      <div className="downImg2">
                        <a
                          className="imga"
                          href="https://xyt.xcc.cn/getpcInfo?sn=1559087899188936704&language=CN&certType=8&url=evchong.com"
                          target="_blank"
                        >
                          <div className="imgdiv"></div>
                          <embed
                            className="imgembed"
                            src="https://program.xinchacha.com/web/1559087899188936704=evchong.com.svg"
                            type="image/svg+xml"
                            pluginspage="//www.adobe.com/svg/viewer/install/"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="down">
                      <a
                        target="_blank"
                        href="https://beian.miit.gov.cn/"
                        rel="noreferrer"
                      >
                        沪ICP备15000733号-1
                      </a>{" "}
                      © www.evchong.com All rights reserved.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
