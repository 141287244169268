export const transWord = {
  navBar: {
    li1: {
      cn: '关于我们',
      en: ''
    },
    li2: {
      cn: '产品与方案',
      en: ''
    },
    li3: {
      cn: '服务与案例',
      en: ''
    },
    li4: {
      cn: '合作伙伴',
      en: ''
    },
    li5: {
      cn: 'ENG/中',
      en: 'ENG/中'
    },
    li6: {
      cn: '服务热线：4008206925',
      en: ''
    },
    p1: {
      cn: '战略投资伙伴',
      en: ''
    },
    p2: {
      cn: '产品概览',
      en: ''
    },
    p3: {
      cn: '智能充电解决方案',
      en: ''
    },
    p4: {
      cn: '产品介绍',
      en: ''
    },
    p5: {
      cn: '服务概览',
      en: ''
    },
    p6: {
      cn: '案例介绍',
      en: ''
    },
    p7: {
      cn: '企业资质',
      en: ''
    },
  },
  page1: {
    w1: {
      cn: '森通智达—低碳出行服务商',
      en: ''
    },
    w2: {
      cn: '集社区充电、快充储能、电池延保和梯次利用于一体的新能源汽车服务平台',
      en: ''
    },
    w3: {
      cn: '累计充电量',
      en: ''
    },
    w4: {
      cn: '服务用户数',
      en: ''
    },
    w5: {
      cn: '服务城市数',
      en: ''
    },
  }
}